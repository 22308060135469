import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Search from '../components/search.vue'
import Deatil from '../components/detail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/search',
  //   name: 'Search',
  //   component: Search
  // },
  {
    path: '/deatil',
    name: 'Deatil',
    component: Deatil
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
