import axios from 'axios'
// const instance = axios.create({
//   baseURL: 'http://192.168.31.161:8080',
//   timeout: 1000,
//   headers: { 'Content-Type': 'application/json' }
// })

if (process.env.NODE_ENV === 'development') {
  // http://192.168.0.101:8080/  http://192.168.0.104:8080/
  // axios.defaults.baseURL = 'http://192.168.0.104:8080/look'
  axios.defaults.baseURL = 'http://192.168.16.102:8080/look'
} else {
  axios.defaults.baseURL = 'http://47.94.239.229:80/look'
}
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response.data
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default axios
