import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'

import Vant from 'vant'
import 'vant/lib/index.css'
import axios from '@/api/request.js'
import './assets/css/index.css'
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole)
var _ = require('lodash')

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$lodash = _

Vue.use(Vant)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
