<template>
  <div class="home">
    <div class="home-search">
      <div class="search-box_">
        <van-search @input="onSearch" shape="round" right-icon="search" left-icon="" background="#fff"
          v-model="valueSearch" placeholder="想买什么 输入什么" @search="onSearch">
        </van-search>
      </div>
    </div>
    <div class="home-tabs">
      <div class="tabs-box">
        <div class="tabs">
          <div class="fuwu" @click="getFuwu">
            <div>系统</div>
            <div>
              <img src="../assets/jiantou.png" alt="">
            </div>
          </div>
          <div class="moren" @click="getMoren">
            <div>{{ MoRenPaiXuLink }}</div>
            <div>
              <img src="../assets/jiantou.png" alt="">
            </div>
          </div>
          <div class="shaixuan" @click="getShaiXuan">
            <div>筛选</div>
            <div>
              <img src="../assets/home.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-list v-model="loading"  :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="home-content-layout" @touchstart="detailUse(item)" @touchmove="datailCancel"  v-for="item in listLoadCurrent"
        :key="item.id">
        <div class="home-content">
          <div class="home-content-left">
            <img :src="item.rootImageUrl" alt="">
          </div>
          <div class="home-content-right">
            <div class="header">
              <div class="header-left">
                <span>账号编码：</span>
                <span style="font-weight:bold">{{ item.commodityTitle }}</span>
              </div>
              <div class="header-right">
                <span>售价：</span>
                <span style="color:red;font-weight: 700">￥{{ item.price }}</span>
              </div>
            </div>
            <!-- <div class="price">
            <div class="price-left">
              <span>售价：</span>
              <span style="color:red;font-weight: 700">￥{{item.price}}</span>
            </div>
            <div class="price-right">
              <span>编码：{{item.commodityCode}}</span>
            </div>
          </div> -->
            <div class="describe">
              <p>
                描述：{{ item.commodityIntroduce.slice(0, 31) }}{{ item.commodityIntroduce.length >= 31 ? '...' : '' }}
              </p>
            </div>
          </div>
        </div>
        <div class="collection-layout">
          <div style="color:#515254;">
            已有
            <span style="color:#eb3a31;font-weight:900;">
              {{ item.quantity }}
            </span>
            人收藏
          </div>
          <div v-if="item.timeLimit" style="width:60px; padding:2px;background-color:#e4412a;color:#fff;text-align: center;border-radius:5px;">
            <span>限时秒杀</span>
          </div>
        </div>
      </div>
    </van-list>
    <van-popup v-model="show" position="right" :style="{ height: '100%', width: '80%' }">
      <div class="box">
        <div class="fuwu-box-up">
          <div class="fuwu-box-up-content">
            <div class="title">系统</div>
            <div class="content">
              <div class="content-box" :class="{ bgc: item.value == systemValue }" @click="getContentBox(item)"
                v-for="(item, index) in fuwuList" :key="index">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <!-- <div style="height:10%;width: 100%;"></div> -->
        <div class="fuwu-box-down">
          <div class="left" @touchstart="resetSystem">重置</div>
          <div class="right" @touchstart="submitSystem">确认</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showFooter" position="bottom" :style="{ height: '30%' }">
      <div class="moRenPaiXuBox">
        <div @click="getMoRenPaiXuLink(item)" :class="{ moRenPaiXuLink: item.show }" class="moRenPaiXu"
          v-for="(item, index) in moRenPaiXuList" :key="index">
          {{ item.name }}
        </div>
      </div>

    </van-popup>
    <van-popup v-model="showShaiXuan" position="right" :style="{ height: '100%', width: '80%' }">
      <div class="box-shaixuan">
        <div class="shaixuan-box-up">
          <div class="shaixuan-box-up-content" v-for="(item, key) in shuaiXuan" :key="key">
            <div class="title">{{ item.name }}</div>
            <div class="search" v-if="item.name == '价格区间'">
              <div class="box1">
                <van-field type="number" v-model="formPrice.value_search1" label="" placeholder="最低价" />
              </div>
              <div class="box2">—</div>
              <div class="box3">
                <van-field type="number" v-model="formPrice.value_search2" label="" placeholder="最高价" />

              </div>
            </div>

            <div class="content">
              <div class="content-box" :class="{ bgc: item.value === authValue }" @click="getContentBoxRightSearch(item)"
                v-for="(item, index) in item.shaiXuanList" :key="index">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <!-- <div class="shaixuan-box-up">
          <div class="shaixuan-box-up-content" v-for="(item,key) in shuaiXuan1" :key="key">
            <div class="title">{{item.name}}</div>

            <div class="content">
              <div class="content-box" :class="{ bgc: item.show }" @click="getContentBoxRightSearch1(item)"
                v-for="(item, index) in item.shaiXuanList" :key="index">{{
                item.name }}</div>
            </div>
          </div>
        </div>
        <div class="shaixuan-box-up">
          <div class="shaixuan-box-up-content" v-for="(item,key) in shuaiXuan2" :key="key">
            <div class="title">{{item.name}}</div>

            <div class="content">
              <div class="content-box" :class="{ bgc: item.show}" @click="getContentBoxRightSearch2(item)"
                v-for="(item, index) in item.shaiXuanList" :key="index">{{
                item.name }}</div>
            </div>
          </div>
        </div>
        <div class="shaixuan-box-up">
          <div class="shaixuan-box-up-content" v-for="(item,key) in shuaiXuan3" :key="key">
            <div class="title">{{item.name}}</div>

            <div class="content">
              <div class="content-box" :class="{ bgc: item.show }" @click="getContentBoxRightSearch3(item)"
                v-for="(item, index) in item.shaiXuanList" :key="index">{{
                item.name }}</div>
            </div>
          </div>
        </div>
        <div class="shaixuan-box-up">
          <div class="shaixuan-box-up-content" v-for="(item,key) in shuaiXuan4" :key="key">
            <div class="title">{{item.name}}</div>

            <div class="content">
              <div class="content-box" :class="{ bgc: item.show }" @click="getContentBoxRightSearch4(item)"
                v-for="(item, index) in item.shaiXuanList" :key="index">{{
                item.name }}</div>
            </div>
          </div>
        </div> -->
        <!-- <div style="height:10%;width: 100%;"></div> -->
        <div class="shaixuan-box-down">
          <div class="left" @touchstart="resetSystemRight">重置</div>
          <div class="right" @touchstart="submitSystemRight">确认</div>
        </div>
      </div>
      <div style="height:100px;width:100%;"></div>
    </van-popup>
    <div class="backUp" @touchend="backUpClick" v-if="this.scrollTop > 200">
      回到顶部
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import axios from 'axios'
// import search from '../components/search.vue'
export default {
  name: 'Home',
  components: {
    // search
  },
  data () {
    return {
      scrollTop: 0,
      authValue: '',
      authValue1: [],
      authValue2: [],
      authValue3: [],
      authValue4: [],
      MoRenPaiXuLink: '默认排序',
      systemValue: '',
      valueSearch: '',
      listLoad: [],
      listLoadCurrent: [],
      loading: false,
      finished: false,
      show: false,
      showFooter: false,
      showShaiXuan: false,
      fuwuList: [
        {
          name: 'QQ',
          value: 'QQ'
        },
        {
          name: '微信',
          value: 'WE_CHAT'
        }

      ],
      bgc: false,
      moRenPaiXuList: [
        {
          name: '默认排序',
          show: false,
          // 排序字段 create_date/price
          property: 'create_date',
          // 排序类型 1.ASC - 升序 2.DESC - 倒序
          orderType: 'ASC'
        },
        {
          name: '最新发布',
          show: false,
          // 排序字段 create_date/price
          property: 'create_date',
          // 排序类型 1.ASC - 升序 2.DESC - 倒序
          orderType: 'DESC'
        },
        {
          name: '价格最低',
          show: false, // 排序字段 create_date/price
          property: 'price',
          // 排序类型 1.ASC - 升序 2.DESC - 倒序
          orderType: 'ASC'
        },
        {
          name: '价格最高',
          show: false,
          // 排序字段 create_date/price
          property: 'price',
          // 排序类型 1.ASC - 升序 2.DESC - 倒序
          orderType: 'DESC'
        },
        {
          name: '收藏量',
          show: false,
          // 排序字段 create_date/price
          property: 'quantity',
          // 排序类型 1.ASC - 升序 2.DESC - 倒序
          orderType: 'DESC'
        }

      ],
      shuaiXuan: {
        jiage: {
          name: '价格区间',
          shaiXuanList: []
        },
        yingxiong: {
          name: '实名认证',
          shaiXuanList: [
            {
              name: '可以二次',
              show: false,
              value: 'CAN_BE_TWICE'
            },
            {
              name: '包人脸识别',
              show: false,
              value: 'FACE_RECOGNITION'
            }

          ]
        }
      },
      shuaiXuan1: {
        name: '',
        shaiXuanList: []
      },
      shuaiXuan2: {
        name: '',
        shaiXuanList: []
      },
      shuaiXuan3: {
        name: '',
        shaiXuanList: []
      },
      shuaiXuan4: {
        name: '',
        shaiXuanList: []
      },
      formPrice: {
        value_search1: '',
        value_search2: ''
      },
      currentPage: 2,
      listMember: {

        pageSize: 10,
        currentPage: 1,
        condition: {
          // 账号编码/账号标题, 头部输入框
          commodityCodeOrTitle: '',
          // 实名认证
          realName: '',
          // 最低价格
          minPrice: '',
          // 最高价格
          maxPrice: '',
          // 具体包含的道具查询条件
          propsIds: null,
          // 账号系统
          accountSystem: ''
        },
        // "property": "create_date",
        // "orderType": "",

        // 排序字段 create_date/price
        property: 'create_date',
        // 排序类型 1.ASC - 升序 2.DESC - 倒序
        orderType: 'DESC'
      },
      timerList: null,
      searchList: [],
      touchTimer: null,
      totalPage: 0,
      percentage: '',
      scrollTopType: 'liulanqi'
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.scrollEvent, false)
      document.documentElement.scrollTop = this.$store.state.scrollTop
      document.body.scrollTop = this.$store.state.scrollTop
    })
  },
  activated () {
    const url = `${window.location.href}`.split('://')[1].split('.cj623037.com')[0]
    this.percentage = url
    this.$nextTick(() => {
      window.addEventListener('scroll', this.scrollEvent, false)
      document.documentElement.scrollTop = this.$store.state.scrollTop
      document.body.scrollTop = this.$store.state.scrollTop
    })
  },
  watch: {
    'formPrice.value_search1': {
      handler (val) {
        this.listMember.condition.minPrice = val
      }
    },
    'formPrice.value_search2': {
      handler (val) {
        this.listMember.condition.maxPrice = val
      }
    }
  },
  deactivated () {
    window.removeEventListener('scroll', this.scrollEvent, false)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollEvent, false)
  },
  methods: {
    backUpClick () {
      this.scrollTop = 0
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      this.$store.state.scrollTop = 0
    },
    scrollEvent (e) {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      // console.log(document.body.scrollTop, document.documentElement.scrollTop)

      if (scrollTop / 1 === 0) return
      // console.log(scrollTop)
      this.scrollTop = scrollTop
      this.$store.state.scrollTop = scrollTop
    },
    datailCancel () {
      // console.log('touchmove===>')
      if (this.touchTimer) clearTimeout(this.touchTimer)
    },

    detailUse (item) {
      clearTimeout(this.touchTimer)
      this.touchTimer = setTimeout(() => {
        // console.log(item, 'detail===>', item.id, this.percentage)
        this.$router.push({
          name: 'Deatil',
          query: {
            id: item.id,
            percentage: this.percentage
          }
        })
      }, 250)
    },
    resetCheckbox (shuaiXuan1) {
      shuaiXuan1.shaiXuanList.map((item) => {
        item.show = false
      })
    },
    chouQuIdFunc (authValue) {
      const idArr = []
      authValue.map((item) => {
        if (item.show) {
          idArr.push(item.id)
        }
      })
      return idArr
    },
    resetSystemRight () {
      this.formPrice = {
        value_search1: '',
        value_search2: ''
      }
      this.authValue = ''
      this.authValue1 = []
      this.authValue2 = []
      this.authValue3 = []
      this.authValue4 = []
      this.listMember.condition.minPrice = ''
      this.listMember.condition.maxPrice = ''
      this.listMember.condition.realName = ''
      this.resetCheckbox(this.shuaiXuan1.taozhuan)
      this.resetCheckbox(this.shuaiXuan2.qiangxie)
      this.resetCheckbox(this.shuaiXuan3.pifu)
      this.resetCheckbox(this.shuaiXuan4.remen)
      this.listMember.condition.propsIds = []
      this.commodityListMember(this.listMember)
      this.showShaiXuan = false
    },
    submitSystemRight () {
      const arr1 = this.chouQuIdFunc(this.authValue1)
      const arr2 = this.chouQuIdFunc(this.authValue2)
      const arr3 = this.chouQuIdFunc(this.authValue3)
      const arr4 = this.chouQuIdFunc(this.authValue4)
      const propsIds = [...arr1, ...arr2, ...arr3, ...arr4]
      this.listMember.condition.propsIds = propsIds
      // console.log(this.listMember, 'listMember===>')
      this.commodityListMember(this.listMember)
      this.showShaiXuan = false
    },
    resetSystem () {
      this.systemValue = ''
      this.listMember.condition.accountSystem = ''
      this.commodityListMember(this.listMember)

      this.show = false
    },
    submitSystem () {
      this.commodityListMember(this.listMember)
      this.show = false
    },
    onSearch () {
      this.listLoadCurrent = []
      this.listMember.condition.commodityCodeOrTitle = this.valueSearch
      clearTimeout(this.timerList)
      this.timerList = setTimeout(() => {
        // console.log(this.valueSearch, 'onSearch====>')
        this.commodityListMember(this.listMember)
      }, 400)
    },
    getSearch () {
      this.$router.push({
        path: '/search'
      })
    },
    onLoad () {
      this.loading = true
      this.listMember.currentPage += 1
      this.listMember.pageSize = 10
      // pageSize: 10,
      //   currentPage: 1,
      this.listMember.condition.percentage = this.percentage
      this.$axios.request({
        // `url` 是用于请求的服务器 URL
        url: '/platform/commodity/list/member',
        // `method` 是创建请求时使用的方法
        method: 'post', // default
        data: this.listMember
      }).then((res) => {
        // console.log(res.data)
        if (res.data.records == null) {
          this.loading = false
          this.finished = true
        }
        this.listLoadCurrent.push(...res.data.records)
        this.totalPage = res.data.totalSize

        this.loading = false
        if (this.totalPage <= this.listLoadCurrent.length) {
          this.finished = true
        }
        // const listLoadCopy = JSON.parse(JSON.stringify(this.listLoad))
        // this.listLoadCurrent = listLoadCopy.splice(this.listLoadCurrent.length, this.currentPage)
        // console.log(this.listLoadCurrent, this.listLoadCurrent.length)
      })
      // // 异步更新数据
      // // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   const listLoadCopy = JSON.parse(JSON.stringify(this.listLoad))
      //   this.listLoadCurrent.push(...listLoadCopy.splice(this.listLoadCurrent.length, this.listLoadCurrent.length + this.currentPage))
      //   // 加载状态结束
      //   this.loading = false

      //   // 数据全部加载完成
      //   if (this.listLoadCurrent.length >= this.listLoad.length) {
      //     this.finished = true
      //   }
      // }, 2000)
    },
    getFuwu () {
      // this.systemValue = 'QQ'
      this.show = true
    },
    getMoren () {
      this.showFooter = true
    },
    getShaiXuan () {
      this.showShaiXuan = true
      // console.log('000')
    },
    getContentBox (item) {
      this.systemValue = item.value
      this.listMember.condition.accountSystem = item.value
      // console.log(this.systemValue, item)
    },
    getContentBoxRightSearch (item) {
      // console.log(item, 'gogo==>')
      this.authValue = item.value
      if (['FACE_RECOGNITION', 'CAN_BE_TWICE'].includes(item.value)) this.listMember.condition.realName = item.value
    },

    getContentBoxRightSearch1 (item) {
      item.show = !item.show
      if (item.show) {
        this.authValue1.push(item)
      } else {
        this.authValue1.map((item, index) => {
          if (!item.show) {
            this.authValue1.splice(index, 1)
          }
        })
      }
      // console.log(this.authValue1, 'this.authValue1==>')
    },
    getContentBoxRightSearch2 (item) {
      item.show = !item.show
      if (item.show) {
        this.authValue2.push(item)
      } else {
        this.authValue2.map((item, index) => {
          if (!item.show) {
            this.authValue2.splice(index, 1)
          }
        })
      }
      // console.log(this.authValue2, 'this.authValue2==>')
    },
    getContentBoxRightSearch3 (item) {
      item.show = !item.show
      if (item.show) {
        this.authValue3.push(item)
      } else {
        this.authValue3.map((item, index) => {
          if (!item.show) {
            this.authValue3.splice(index, 1)
          }
        })
      }
      // console.log(this.authValue3, 'this.authValue3==>')
    },
    getContentBoxRightSearch4 (item) {
      // console.log(item, 'gogo==>')
      item.show = !item.show
      if (item.show) {
        this.authValue4.push(item)
      } else {
        this.authValue4.map((item, index) => {
          if (!item.show) {
            this.authValue4.splice(index, 1)
          }
        })
      }
      // console.log(this.authValue4, 'this.authValue4==>')
    },
    getMoRenPaiXuLink (obj) {
      // console.log(obj)
      this.listMember.property = obj.property
      this.listMember.orderType = obj.orderType
      this.MoRenPaiXuLink = obj.name
      this.commodityListMember(this.listMember)
      this.showFooter = false
      // this.moRenPaiXuList.forEach((item) => {
      //   if (obj.name === item.name) {
      //     item.show = true
      //   } else {
      //     item.show = false
      //   }
      // })
      // 业务代码，在此处
    },
    // 客户商品列表
    commodityListMember (listMember = {}) {
      this.listMember.currentPage = 1
      this.listMember.pageSize = 10
      this.loading = true
      this.listMember.condition.percentage = this.percentage
      this.$axios.request({
        // `url` 是用于请求的服务器 URL
        url: '/platform/commodity/list/member',
        // `method` 是创建请求时使用的方法
        method: 'post', // default
        data: this.listMember
        // data: {
        //   ...listMember,
        //   percentage: this.$route.query.percentage
        // }
      }).then((res) => {
        // console.log(res.data)
        this.listLoadCurrent = res.data.records
        this.totalPage = res.data.totalSize
        this.finished = false
        this.loading = false
        // const listLoadCopy = JSON.parse(JSON.stringify(this.listLoad))
        // this.listLoadCurrent = listLoadCopy.splice(this.listLoadCurrent.length, this.currentPage)
        // console.log(this.listLoadCurrent, this.listLoadCurrent.length)
      })
    },
    filterSearchList (list) {
      // const arrKey =  ['piFuShu','guiZuDengJi','shiMing','xiYouYingXiong']
      const arr = []
      list.map((item) => {
        var params = {
          name: '',
          id: '',
          value: '',
          shaiXuanList: []
        }
        params.id = item.id
        params.value = item.id
        params.name = item.propsName

        item.childPropsVO.map((obj) => {
          var keyObj = {
            name: '',
            id: '',
            show: false,
            value: ''
          }
          keyObj.id = obj.id
          keyObj.value = obj.id
          keyObj.name = obj.propsName
          params.shaiXuanList.push(keyObj)
        })
        arr.push(params)
      })

      return arr
    },
    // 获取所有查询条件 /platform/props/findPropsAll
    propsFindPropsAll () {
      this.$axios({
        url: '/platform/props/findPropsAll',
        method: 'get',
        data: {}
      }).then((res) => {
        // console.log(res.data)
        const arr = this.filterSearchList(res.data)
        this.shuaiXuan1 = { taozhuan: arr[0] }
        this.shuaiXuan2 = { qiangxie: arr[1] }
        this.shuaiXuan3 = { pifu: arr[2] }
        this.shuaiXuan4 = { remen: arr[3] }
        // console.log(this.shuaiXuan4, 'this.shuaiXuan4==>')
        // this.shuaiXuan1 = obj
        // this.shuaiXuan2 = obj
        // this.shuaiXuan3 = obj
        // this.shuaiXuan4 = obj
        // this.searchList = res.data
      })
    }

  },

  created () {
    // const urlC = 'http://aaahp623037.com/'
    const url = `${window.location.href}`.split('://')[1].split('.cj623037.com')[0]
    this.percentage = url
    // this.percentage = this.$route.query.percentage
    // console.log(this.$route.query.percentage)
    this.propsFindPropsAll()
    this.listMember.currentPage = 0
    // this.commodityListMember(this.listMember)
    // this.commodityListMember(this.listMember)
    // this.getContentBox({
    //   name: '全部',
    //   bgc: false
    // })
  }
}
</script>

<style lang="less" scope>
html,
body,
#app {
  padding: 0;
  margin: 0;
  width: 100%;
  // height: 100%;
  box-sizing: border-box;
  font-size: small;
  background-color: #f7f8f8;
}

.backUp {
  position: fixed;
  bottom: 4px;
  right: 4px;
  background-color: #1a73e8;
  padding: 4px 6px;
  font-size: 24px;
  color: #fff;
  border-radius: 10px 0 0 10px;
}

.home {
  position: relative;
  height: 100%;
  // width: 100%;
  // height: 100%;
  padding: 0 30px;

  .home-search {
    margin-top: 20px;

    .search-box_ {
      // display: flex;
      border-radius: 24px;
      overflow: hidden;

      .van-search {

        // padding: 5px 0;
        .van-field__body {
          font-size: 24px;
        }
      }

      .van-search .van-cell {
        background-color: #fff;
      }

      .van-icon-search:before {
        color: #5468ff;
        font-size: 0.4rem;
        margin-top: 5px;
      }
    }

  }

  .home-tabs {
    position: sticky;
    top: 0;

    .tabs-box {
      margin-top: 20px;

      background-color: #fff;
      border-radius: 24px;

      .tabs {
        display: flex;
        width: 100%;
        line-height: 80px;
        height: 80px;

        .fuwu {
          flex: 1;
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          font-size: 24px;

          img {
            width: 24px;
          }

        }

        .moren {

          flex: 1;
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          font-size: 24px;
          border-left: 1px solid #f3f5f9;
          border-right: 1px solid #f3f5f9;

          img {
            width: 24px;
          }
        }

        .shaixuan {
          flex: 1;
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          font-size: 24px;

          img {
            width: 24px;
          }

        }
      }
    }
  }
  .home-content-layout{
    background-color: #fff;
    .collection-layout {
      display: flex;
      justify-content: space-between;
      padding:10px 30px;
      padding-top:0px;
    }
  }
  .home-content {
    display: flex;
    margin-top: 20px;
    background-color: #fff;

    .home-content-left {
      width: 200px;
      padding: 10px;

      img {
        width: 100%;
      }
    }

    .home-content-right {
      width: 100%;
      padding: 10px;

      .header {
        display: flex;

        .header-left {
          width: 50%;
        }

        .header-right {
          width: 50%;
        }
      }

      .price {
        display: flex;
        margin-top: 20px;

        .price-left {
          width: 50%;
        }

        .price-right {
          width: 50%;
        }
      }

      .describe {
        p {
          word-break: break-all;
        }
      }
    }
  }

  .van-popup {
    .box {
      width: 100%;
      height: 100%;

      .fuwu-box-up {
        // height: 90%;
        overflow-y: auto;

        .fuwu-box-up-content {
          margin: 30px 20px 20px 20px;

          .title {
            font-weight: 800;
            font-size: 30px;
            margin: 15px 0;
          }

          .content {
            display: flex;
            flex-wrap: wrap;

            // #f3f5f9
            .content-box {
              width: 26%;
              height: 60px;
              line-height: 60px;
              text-align: center;
              border-radius: 10px;
              margin-left: 10px;
              margin-bottom: 10px;
              border: solid 1px #fff;
              background-color: #f3f5f9;
            }

            .bgc {
              border: solid 1px #5468ff;
              background-color: #eef0ff;
            }
          }
        }
      }

      .fuwu-box-down {
        // position:fixed;
        // bottom: 0;
        height: 120px;
        width: 100%;
        line-height: 90px;
        text-align: center;
        display: flex;
        justify-content: space-around;

        .left {
          flex: 1;
          margin: 20px;
          font-size: 24px;
          color: #5468ff;
          border-radius: 10px;
          background-color: #eef0ff;
        }

        .right {
          flex: 1;
          font-size: 24px;
          margin: 20px 20px 20px 0;
          color: #ffffff;
          border-radius: 10px;
          background-color: #5468ff;
        }
      }
    }

    .moRenPaiXu {
      width: 100%;
      text-align: center;
      font-size: 34px;
      padding: 24px 0;
    }

    .moRenPaiXuBox {
      margin-bottom: 10px;

    }

    .moRenPaiXuLink {
      background-color: #f2f3f5;
    }

    .box-shaixuan {
      width: 100%;
      // height: 90%;
      overflow-y: auto;

      .shaixuan-box-up {
        // height: 90%;

        .shaixuan-box-up-content {
          margin: 30px 20px 20px 20px;

          .title {
            font-weight: 800;
            font-size: 30px;
            margin: 15px 0;
          }

          .search {
            display: flex;
            margin: 10px 26px 30px 26px;

            .box1 {
              flex: 2;

              .van-cell {
                // height: 60px;
                border-radius: 5px;
                border: solid 1px #c4c9cf;
                background-color: #ffffff;
              }

              .van-field__body {
                margin-left: 10px;
                font-size: 24px;
                // line-height: 22px;
              }

              // .van-search {
              //   padding: 0;
              // }

              // .van-search__content {
              //   padding-left: 0;
              // }

            }

            .box2 {
              flex: 1;
              line-height: 60px;
              text-align: center;
            }

            .box3 {
              flex: 2;

              .van-cell {
                // height: 60px;
                border-radius: 5px;
                border: solid 1px #c4c9cf;
                background-color: #ffffff;
              }

              // .van-search {
              //   padding: 0;
              // }

              .van-field__body {
                margin-left: 10px;
                font-size: 24px;
              }

              // .van-search__content {
              //   padding-left: 0;
              // }
            }
          }

          .content {
            display: flex;
            flex-wrap: wrap;

            // #f3f5f9
            .content-box {
              height: 60px;
              padding: 2px 10px;
              line-height: 60px;
              text-align: center;
              border-radius: 10px;
              margin-left: 10px;
              margin-bottom: 10px;
              border: solid 1px #fff;
              background-color: #f3f5f9;
            }

            .bgc {
              border: solid 1px #5468ff;
              background-color: #eef0ff;
            }
          }
        }
      }

      .shaixuan-box-down {
        // position:fixed;
        // bottom: 0;
        height: 120px;
        width: 100%;
        line-height: 90px;
        text-align: center;
        display: flex;
        justify-content: space-around;

        .left {
          flex: 1;
          margin: 20px;
          font-size: 24px;
          color: #5468ff;
          border-radius: 10px;
          background-color: #eef0ff;
        }

        .right {
          flex: 1;
          font-size: 24px;
          margin: 20px 20px 20px 0;
          color: #ffffff;
          border-radius: 10px;
          background-color: #5468ff;
        }
      }
    }
  }

}
</style>
