<template>
  <div class="home">
    <div class="return-box clearfix" @click="returnPage">
      <div class="retrun-anniu"><span class="iconfont">&#xe60f;</span></div>
      <div class="zhanghao-detail">账号详情</div>

    </div>
    <div class="page">

      <!-- <div class="home-content">
        <div class="home-content-left" @touchstart="getLookMain">
          <img :src="dataDetail.rootImageUrl" alt="">
        </div>

        <div class="home-content-right">

          <div class="describe">
            <p>
              {{dataDetail.commodityIntroduce}}
            </p>
          </div>
        </div>
      </div>

      <div class="home-detail">
        <div class="home-detail-left">
          <div>
            <span>价格：</span>
            <span>{{dataDetail.price}}</span>
          </div>
          <div>
            <span>系统：</span>
            <span>{{dataDetail.accountSystem}}</span>
          </div>
        </div>
        <div class="home-detail-right">

          <div>
            <span>编码：</span>
            <span @click="copyValue(dataDetail.commodityCode,$event)" >
              {{dataDetail.commodityCode}}
          </span>
          </div>
          <div>
            <span>实名：</span>
            <span>{{dataDetail.realName}}</span>
          </div>
        </div>
      </div>
      <div class="home-create-time">
        <div>
          <span>上架时间：</span>
          <span>{{dataDetail.createdDate}}</span>
        </div>
        <div>

        </div>
      </div> -->
      <div class="page-header">
        <div class="title">
          <img src="@/assets/price-title.png" alt="">
          <span>{{ dataDetail.accountSystem | systemFilter }}</span>
        </div>
        <div class="state">
          <span>已上架</span>
        </div>
      </div>
      <div class="page-price">
        <span>￥{{ dataDetail.price }}</span>
      </div>
      <div class="page-content">
        <van-field :style="{ fontSize: fontSize }" v-model="dataDetail.commodityIntroduce"  autosize label=""
          type="textarea" placeholder="请输入" />
      </div>
      <!-- <div class="page-content" :style="{ height: heightContent }">
        <p v-html="dataDetail.commodityIntroduce"></p>
      </div> -->
      <div style="display: flex;justify-content: end;margin:0 15px 10px 15px;">
        <span @click="zhankaiHandler" v-if="zhankaiShow" style="cursor: pointer;">展开详情</span>
        <span @click="shouqiHandler" v-else style="cursor: pointer;">收起详情</span>
      </div>
      <div class="page-footer">
        <div class="page-footer-right">
          <span>实名认证：</span>
          <span>{{ dataDetail.realName | authFilter }}</span>
        </div>
        <div class="page-footer-left" @click="copyValue(dataDetail.commodityCode, $event)">
          商品编号： {{ dataDetail.commodityCode }} <span class="iconfont">&#xe8b0;</span>
        </div>
      </div>
      <div class="auth_box">
        <!-- 上架时间：{{ dataDetail.createDate }} -->
        <div style="display:flex;justify-content: space-between;">
          <div style="color:#515254;">
            已有
            <span style="color:#eb3a31;font-weight:900;">
              {{ dataDetail.quantity }}
            </span>
            人收藏
          </div>
          <div v-if="dataDetail.timeLimit"
            style="width:60px; padding:2px;background-color:#e4412a;color:#fff;text-align: center;border-radius:5px;">
            <span>限时秒杀</span>
          </div>
        </div>
      </div>
    </div>
    <div class="imgBox">
      <div class="phone-img">
        <div class="yuan">&nbsp;</div>
        <span>手机截图</span>
      </div>
      <div class="detailImgBox" @touchmove="getTouchmove" @touchstart="getLookImg(index)"
        v-for="(url, index) in dataDetail.detailImageUrls" :key="index">
        <img :src="url" alt="">
      </div>
    </div>
    <van-image-preview v-model="show" :images="previewArr" @change="onChange_">
      <template v-slot:index>{{ index + 1 }}</template>
    </van-image-preview>
    <!-- <van-image-preview v-model="showMain" :images="[dataDetail.rootImageUrl]" @change="onChangeMian">
      <template v-slot:index>{{indexMain+1}}</template>
    </van-image-preview> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import axios from 'axios'
import handleCopy from './copyText.js'
import { Toast } from 'vant'
export default {
  name: 'deatil',
  components: {
  },
  mounted () {

  },
  data () {
    return {
      fontSize: '12px',
      rows: 4,
      zhankaiShow: true,
      value: '',
      listLoad: [],
      listLoadCurrent: [{}, {}, {}],
      loading: false,
      finished: false,
      currentPage: 2,
      dataDetail: {
        detailImageUrls: []
      },
      show: false,
      index: 0,
      showMain: false,
      indexMain: 0,
      timerDetail: null,
      previewArr: [],
      heightContent: '2.4rem'

    }
  },
  created () {
    this.commodityDetail()
  },
  activated () {
    this.commodityDetail()
  },
  watch: {
    'dataDetail.detailImageUrls': {
      handler (arr) {
        // this.previewArr = [arr[this.index]]
        this.previewArr = this.previewArrFilter(arr[this.index])
      }
    }
  },
  filters: {
    systemFilter (val) {
      const obj = {
        // 1.QQ - 腾讯QQ
        // 2.WE_CHAT - 微信
        QQ: 'QQ',
        WE_CHAT: '微信'
      }
      return obj[val]
    },
    authFilter (val) {
      const obj = {
        // 1.FACE_RECOGNITION - 包人脸识别
        // 2.CAN_BE_TWICE - 可以二次
        CAN_BE_TWICE: '可以二次',
        FACE_RECOGNITION: '包人脸识别'

      }
      return obj[val]
    }
  },
  methods: {
    zhankaiHandler () {
      this.zhankaiShow = !this.zhankaiShow
      this.fontSize = '14px'
      this.rows = '8'
    },
    shouqiHandler () {
      this.zhankaiShow = !this.zhankaiShow
      this.fontSize = '12px'
      this.rows = 4
    },
    // zhankaiHandler () {
    //   this.zhankaiShow = !this.zhankaiShow
    //   this.heightContent = '100%'
    //   this.dataDetail.commodityIntroduce = this.dataDetail.commodityIntroduceSource
    // },
    // shouqiHandler () {
    //   this.zhankaiShow = !this.zhankaiShow
    //   this.heightContent = '2.4rem'
    //   const lengthStr = 31 * 2
    //   var commodityIntroduce = this.dataDetail.commodityIntroduce.slice(0, lengthStr)
    //   if (this.dataDetail.commodityIntroduce.length > lengthStr) {
    //     commodityIntroduce += '...'
    //   }
    //   this.dataDetail.commodityIntroduce = commodityIntroduce.replace(/\\n/g, <br />)
    // },
    previewArrFilter (val) {
      const arr = []
      if (this.dataDetail.detailImageUrls.includes(val)) {
        arr.push(val)
      }
      var strCopy = JSON.parse(JSON.stringify(this.dataDetail))
      strCopy.detailImageUrls.splice(strCopy.detailImageUrls.indexOf(val), 1)
      return [...arr, ...strCopy.detailImageUrls]
    },
    returnPage () {
      this.$router.push({
        name: 'Home',
        query: {
          // percentage: this.$route.query.percentage
          percentage: `${window.location.href}`.split('://')[1].split('.hp623037.com')[0]

        }
      })
    },
    copyValue (text, e) {
      const value = text // 这里设置你要复制的文本信息，可以是text，也可以从e中获取，也可以自定义
      handleCopy(value, e, () => {
        // console.log('这是复制成功回调函数')
        Toast.success('复制成功')
      }, () => {
        // console.log('这是复制失败回调函数')
      })
    },
    getLookMain () {
      this.showMain = true
    },
    getTouchmove () {
      clearTimeout(this.timerDetail)
    },
    getLookImg (index) {
      this.index = index
      // this.previewArr = [this.dataDetail.detailImageUrls[this.index]]
      this.previewArr = this.previewArrFilter(this.dataDetail.detailImageUrls[this.index])
      clearTimeout(this.timerDetail)
      this.timerDetail = setTimeout(() => {
        this.show = true
      }, 250)
    },
    onChange_ (index) {
      this.index = index
      // this.previewArr = [this.dataDetail.detailImageUrls[this.index]]
      // this.previewArr = this.previewArrFilter(this.dataDetail.detailImageUrls[this.index])
    },
    onChangeMian (index) {
      this.indexMain = index
    },
    // 商品详情 /platform/commodity/detail/{id}
    commodityDetail () {
      const id = this.$route.query.id
      this.$axios({
        url: '/platform/commodity/detail',
        methods: 'get',
        params: {
          id: id,
          // percentage: this.$route.query.percentage
          percentage: `${window.location.href}`.split('://')[1].split('.hp623037.com')[0]

        }
      }).then((res) => {
        this.dataDetail = res.data

        // const lengthStr = 31 * 2
        // this.dataDetail.commodityIntroduceSource = this.dataDetail.commodityIntroduce
        // var commodityIntroduce = this.dataDetail.commodityIntroduce.slice(0, lengthStr)
        // if (this.dataDetail.commodityIntroduce.length > lengthStr) {
        //   commodityIntroduce += '...'
        // }
        // this.dataDetail.commodityIntroduce = commodityIntroduce.replace(/\\n/g, <br />)
      })
    },
    onSearch () {
      // console.log(this.value, 'onSearch====>')
    },
    onCancel () {
      // console.log(this.value, 'onCancel====>')
    },

    commodityListMember () {
      this.$axios.request({
        // `url` 是用于请求的服务器 URL
        url: '/platform/commodity/list/member',
        // `method` 是创建请求时使用的方法
        method: 'post', // default
        data: {
          // percentage: this.$route.query.percentage
          percentage: `${window.location.href}`.split('://')[1].split('.hp623037.com')[0]
        }
      }).then((res) => {
        this.listLoad = res.data
        const listLoadCopy = JSON.parse(JSON.stringify(this.listLoad))
        this.listLoadCurrent = listLoadCopy.splice(this.listLoadCurrent.length, this.currentPage)
      })
    },
    onLoad () {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        const listLoadCopy = JSON.parse(JSON.stringify(this.listLoad))
        this.listLoadCurrent.push(...listLoadCopy.splice(this.listLoadCurrent.length, this.listLoadCurrent.length + this.currentPage))
        // 加载状态结束
        this.loading = false

        // 数据全部加载完成
        if (this.listLoadCurrent.length >= this.listLoad.length) {
          this.finished = true
        }
      }, 2000)
    }

  }

}
</script>

<style lang="less" scope>
.clearfix:after {
  /*伪元素是行内元素 正常浏览器清除浮动方法*/
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  *zoom: 1;
  /*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
}

html,
body,
#app {
  padding: 0;
  margin: 0;
  width: 100%;
  // height: 100%;
  box-sizing: border-box;
  font-size: small;
  background-color: #f7f8f8;
}

.home {
  // width: 100%;
  // height: 100%;
  padding: 0 30px;

  .return-box {
    margin-top: 30px;

    .retrun-anniu {
      float: left;
      margin-left: 20px;

      span {
        color: #6375fe;

        font-size: 30px;
      }
    }

    .zhanghao-detail {
      width: 90%;
      float: left;
      text-align: center;
      margin: 0 auto;
      font-weight: bold;
      text-shadow: 0px 0px #000;
      font-size: 30px;
    }

    // padding: 0 20px;
    // padding-top: 20px;

  }

  .page {
    padding-bottom: 10px;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 20px;
    background-color: #fff;

    .zhanghao-detail {
      padding: 0 20px;
      padding-top: 20px;
      width: 100%;
      text-align: center;
      font-weight: 700;
    }

    .page-header {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      padding-top: 20px;

      .title {
        img {
          width: 30px;
          height: 30px;
          vertical-align: middle;
        }

        span {
          margin-left: 5px;
          color: #3f4a58;
        }
      }

      .state {
        color: #3f4a58;
      }
    }

    .page-price {
      display: flex;
      justify-content: flex-end;
      padding: 0 20px;
      padding-top: 10px;
      padding-bottom: 0px;

      span {
        font-weight: 700;
        color: #ff4646;
      }
    }

    .page-content {
      padding: 0px 30px;

      .van-field {
        line-height: 40px;
        font-size: 18px;
      }

    }

    // .page-content {
    //   box-sizing: border-box;
    //   padding: 0px 30px;
    //   overflow: hidden;

    //   white-space: pre-line;
    //   p {
    //     width: 100%;
    //     line-height: 50px;
    //     font-size: 36px;
    //     color: #3a4b60;
    //   }
    // }

    .page-footer {
      display: flex;
      justify-content: space-between;
      padding: 0px 30px;
      padding-bottom: 5px;
      color: #c6c9cf;

      .page-footer-left {
        margin-left: 10px;

      }

      .page-footer-right {}
    }

    .auth_box {
      padding: 0px 30px;
      padding-bottom: 5px;
      color: #c6c9cf;
    }
  }

  .home-content {
    display: flex;

    .home-content-left {
      width: 200px;
      padding: 10px;
      padding-left: 30px;

      img {
        width: 100%;
      }
    }

    .home-content-right {
      width: 100%;
      padding: 10px;

      .describe {}
    }
  }

  .home-detail {
    display: flex;

    .home-detail-left {
      width: 50%;
      margin-left: 30px;
      margin-top: 20px;

      div {
        margin-bottom: 10px;
      }
    }

    .home-detail-right {
      width: 50%;
      margin-left: 30px;
      margin-top: 20px;

      div {
        margin-bottom: 10px;
      }
    }
  }

  .home-create-time {
    display: flex;
    margin-bottom: 20px;

    div {
      margin-left: 30px;
    }
  }

  .imgBox {
    .phone-img {
      margin-top: 30px;
      display: flex;
      margin-left: 10px;
      margin-bottom: 5px;

      .yuan {

        border-radius: 3px;
        width: 3px;
        background-color: #5468ff;
      }

      span {
        margin-left: 5px;
      }
    }

    .detailImgBox {
      margin: 0 auto;
      margin-top: 5px;
      max-width: 100%;
      border-radius: 10px;
      min-height: 300px;
      overflow: hidden;
      background-color: #fff;

      img {

        width: 100%;
      }
    }
  }

}
</style>
